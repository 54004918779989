<template>
    <div class="waiting-payment">
        <div class="waiting-icon">
            <img src="@/assets/svg/v2/payment/pending.svg" alt="Please wait" />
            <h1>{{ $t('payment_process.payment_pending') }}</h1>
        </div>
        <div class="waiting-content">
            <p>{{ $t('payment_process.payment_please_wait') }}</p>
        </div>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from 'vuex'
import { ElMessage } from 'element-plus'

export default {
    props: {
        transactionId: {
            type: String,
            required: true
        }
    },
    emits: ['end'],
    data() {
      return {
        countPending: 0,
        maxPending: 30
      }
    },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        ...mapActions({
            checkTransaction: 'checkTransaction',
        }),
    },
    async mounted() {
        const intervalWaiting = setInterval(async() => {
            await this.checkTransaction(this.transactionId).then(async() => {

                ElMessage({
                    message: this.$t('payment_process.success'),
                    type: 'success',
                    grouping: true,
                })

                this.$emit('end', 'clear');
                clearInterval(intervalWaiting)
            }).catch((err) => {
                if (err !== 'pending') {
                    this.$emit('end', 'clear');
                    clearInterval(intervalWaiting)
                }
            }).finally(async() => {
                this.countPending++;
                if (this.countPending > this.maxPending) {
                    this.$emit('end', 'clear');
                    clearInterval(intervalWaiting)

                    ElMessage({
                        message: this.$t('payment_process.failed'),
                        type: 'warning',
                        grouping: true,
                    })
                }
            })
        }, 2000);
    }
  }
</script>
  
<style lang="scss">
@import '@/assets/styles/root.scss';

.waiting-payment {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .waiting-icon {
        display: flex;

        img {
            margin-right: 10px;
        }
    }
}

@keyframes spinPause {
    0% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    90% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.waiting-icon img {
    display: inline-block;
    animation: spinPause 3s linear infinite;
}
  
@media screen and (max-width: 500px) {

}
</style>
  